/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-extraneous-dependencies */
import { Buffer } from 'buffer'
import { create } from 'ipfs-http-client'
import React, { useRef, useState } from 'react'

import { sendEmailAndCreateToken } from '../../api'
import CsvEmailParser from '../CsvEmailParser'
import Spinner from '../Spinner'

type ImageData = {
	id: string;
	src: string;
};

const projectId = process.env.REACT_APP_INFURA_IPFS_PROJECT_ID
const projectSecret = process.env.REACT_APP_INFURA_IPFS_PROJECT_SECRET
const projectIdAndSecret = `${projectId}:${projectSecret}`
const auth = `Basic ${Buffer.from(projectIdAndSecret).toString('base64')}`

const client = create({
	host: 'ipfs.infura.io',
	port: 5001,
	protocol: 'https',
	headers: {
		authorization: auth,
	},
})

const Image = ({ image }: { image: ImageData }) => (
	<div className="file-item">
		<img alt={`img - ${image.id}`} src={image.src} className="file-img" />
	</div>
)

type CreateNftFormProps = {
	owner: string
}

const CreateNftForm = ({ owner }: CreateNftFormProps) => {
	const [fileUrl, setFileUrl] = useState<string | null>(null)
	const [formInput, updateFormInput] = useState({ name: '', description: '' })
	const [image, setImage] = useState<ImageData>({} as ImageData)
	const [isLoading, setIsLoading] = useState(false)
	const [isUploading, setIsUploading] = useState(false)
	const [emails, setEmails] = useState<string[]>([])
	const [imageKey, setImageKey] = useState('')

	const isDisabled = formInput.name.length === 0 || formInput.description.length === 0 || emails.length === 0 || Object.keys(image).length === 0

	const fileInputRef = useRef<HTMLInputElement>(null)

	const handleInputParentClick = () => {
		fileInputRef.current?.click()
	}

	const hasImage = Boolean(Object.keys(image).length)

	async function onChange(e: React.ChangeEvent<HTMLInputElement>) {
		e.preventDefault()
		/* upload image to IPFS */
		const file = e.target.files?.[0]
		if (!file) return

		try {
			setIsUploading(true)
			const added = await client.add(
				file,
				{
					progress: (prog: any) => console.log(`received: ${prog}`),
				},
			)
			const url = `https://nfts-drop.infura-ipfs.io/ipfs/${added.path}`
			setFileUrl(url)

			/* Update image preview */
			const reader = new FileReader()
			reader.onload = function handleOnLoadEvent(e) {
				setImage({ id: 'id', src: e?.target?.result as string })
			}
			reader.readAsDataURL(file)
		} catch (error) {
			console.log('Error uploading file: ', error)
		}
		setIsUploading(false)
	}

	const uploadToIPFS = async () => {
		const { name, description } = formInput
		if (!name || !description || !fileUrl) {
			return null
		}
		/* first, upload metadata to IPFS */
		const data = JSON.stringify({
			name, description, image: fileUrl,
		})
		try {
			const added = await client.add(data)
			const url = `https://nfts-drop.infura-ipfs.io/ipfs/${added.path}`
			/* after metadata is uploaded to IPFS, return the URL to use it in the transaction */
			return url
		} catch (error) {
			console.log('Error uploading file: ', error)
		}
		return null
	}

	async function listNFTForSale() {
		setIsLoading(true)
		try {
			const url = await uploadToIPFS()
			if (url) {
				await sendEmailAndCreateToken(emails, owner, url)
			}
		} catch (error) {
			console.log(error)
		} finally {
			setIsLoading(false)
		}
	}

	const deleteImage = () => {
		setImage({} as ImageData)
		const randomString = Math.random().toString(36)
		setImageKey(randomString)
	}

	return (
		<div className="flex w-1/2 flex-col bg-white rounded-lg px-10 py-10 mt-4">
			<div className="flex flex-col">
				<div className="text-center font-bold text-violet-800 text-2xl mb-4">
					Create & Share your NFT
				</div>
				<div className="flex flex-col">
					<label
						htmlFor="asset-name"
						className="font-semibold text-sm select-none text-violet-800"
					>
						Asset name
					</label>
					<input
						required
						id="asset-name"
						placeholder="Enter asset Name"
						className="mt-1 border rounded p-2"
						onChange={(e) => updateFormInput({
							...formInput,
							name: e.target.value,
						})}
					/>
				</div>
				<div className="flex flex-col">
					<label
						htmlFor="asset-name"
						className="font-semibold text-sm select-none text-violet-800 mt-4"
					>
						Asset description
					</label>
					<input
						required
						id="asset-name"
						placeholder="Enter asset Name"
						className="mt-1 border rounded p-2"
						onChange={(e) => updateFormInput({
							...formInput,
							description: e.target.value,
						})}
					/>
				</div>
				<p className="font-semibold text-sm select-none text-violet-800 mt-4 mb-1">
					Upload file
				</p>
				{hasImage ? (
					<Image image={image} />
				) : (
					<section className="flex flex-col mt-1 p-4 border-dashed border-2 border-indigo-600 rounded-lg justify-center">
						<p className="text-violet-900 text-center mb-1 cursor-default">
							Drop files here
						</p>
						<p className="text-gray-400 text-center text-xs cursor-default">
							Files supported: JPG, PNG
						</p>
						{isUploading ? (
							<Spinner />
						) : (
							<button
								type="button"
								onClick={handleInputParentClick}
								className="text-center self-center font-semibold my-1 w-1/2 bg-violet-800 text-white rounded-lg p-2 shadow-lg cursor-pointer"
							>
								<input
									ref={fileInputRef}
									key={imageKey || ''}
									onChange={(e) => onChange(e)}
									type="file"
									name="uploadfile"
									id="img"
									className="hidden pointer-events-none"
								/>
								<label
									className="pointer-events-none"
									htmlFor="img"
								>
									Choose File
								</label>
							</button>
						)}
						<p className="text-gray-400 text-center text-xs cursor-default">
							Maximum size: 5 MB
						</p>
					</section>
				)}
				{hasImage && (
					<button
						type="button"
						onClick={deleteImage}
						className="font-bold mt-2 bg-violet-800 text-white rounded-lg p-2 shadow-lg"
					>
						Delete file
					</button>
				)}

				<div className="my-6">
					<p className="font-semibold text-sm select-none text-violet-800 mb-1">
						Upload CSV file
					</p>
					<CsvEmailParser emails={emails} setEmails={setEmails} />
				</div>
				<div
					className={`text-center self-center w-1/2 font-bold mt-2 rounded-lg p-2 shadow-lg ${
						isDisabled
							? 'bg-gray-100 text-black'
							: 'bg-violet-800 text-white'
					}`}
				>
					{isLoading ? (
						<Spinner />
					) : (
						<button
							className={
								isDisabled
									? 'opacity-50 cursor-not-allowed'
									: ''
							}
							type="button"
							onClick={listNFTForSale}
							disabled={isDisabled}
						>
							Create & Share NFT
						</button>
					)}
				</div>
			</div>
		</div>
	)
}

export default CreateNftForm
